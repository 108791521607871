@import '../../../../../variables';
@import '../../../../../../../../node_modules/@idexx/spot/dist/styles/shared/color';
@import '../../../../../mixins';
@import '../../../../../functions';

.headerDivider {
    height: 3px;
}

.copyItemDropdown {
    padding-bottom: 10px;
}

.addToPracticesTitle {
    padding-top: 10px;
}

.selectPracticesCancelButton {
    text-align: left;
    width: 100%;
    color: $spot-color-teal-35;
}

.addToPracticesButton {
    display: flex;
    text-align: right;
    width: 100%;
}

.selectionError {
    background-color: $spot-feedback-color-error-background-on-light;
    color: $spot-feedback-color-error-on-light;
    height: 30px;
    width: 100%;
    padding-left: 15px;
    margin-bottom: 10px;
}

.selectionErrorText {
    margin-left: 25px;
    padding-top: 3px;
}

.practicesMainContainer {
    max-height: 800px;
}

.footerStyles {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.footerButtonStyle {
    margin: 16px 0px 0px 16px;
}

@media only screen and (max-width: 460px) {
    .addToPracticesButton {
        flex-direction: column-reverse;
    }
    .footerStyles {
        flex-direction: column-reverse;
    }
    .selectPracticesCancelButton {
        text-align: center;
    }
}
