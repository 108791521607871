.delete-widget {
  display: flex;
  user-select: none;
  color: $spot-text-color-primary-on-light;

  .delete-widget-action {
    display: flex;
    align-items: center;
  }

  .delete-widget-icon {
    @extend .spot-link__icon;
    fill: $spot-text-color-primary-on-light;

    &.clickable {
      fill: #00a7b5;
    }
  }

  .clickable {
    cursor: pointer;
    color: #00a7b5;
  }

  .delete-widget-options {
    padding: 0 20px;
  }

  .delete-widget-link-divider {
    color: $spot-color-neutral-74;
    padding: 0 15px;
  }
}