@import '../../variables';
@import '../../mixins';
@import '~@idexx/spot/dist/styles/shared/spot_tokens';

.analytics-page {
    overflow: visible;
    margin: 0 -24px;

    .content {
        margin-top: 32px;

        @include media-breakpoint-up(sm) {
            margin-top: 42px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 52px;
        }
    }

    .looker-frame {
        border: none;
        width: 100%;
    }

    .look-iframe {
        height: 75vh;
    }

    .looker-frame-hidden {
        visibility: hidden;
    }

    .spot-tabs__content > div {
        padding-top: 12px;
    }

    .looker-spinner {
        padding-top: $spot-space-inset-three-quarter;
        padding-left: $spot-space-inset-one-and-a-half;
        padding-right: $spot-space-inset-one-and-a-half;

        @include media-breakpoint-up(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .analytics-dashboard-select {
        width: max-content;
    }

    .spot-dropdown__content {
        overflow: visible;
        overflow-y: auto;
        max-height: 275px;
        max-width: 100%;
        min-width: 240px;
        width: 240px;
        left: 0;
    }
}

.analytics-feature-content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
        display: flex;
        flex-direction: row;
    }
}

.analyticsAdvertisement {
    display: flex;
    flex-direction: column;
    padding-right: 49px;

    @include media-breakpoint-up(xl) {
        flex-direction: row;
        padding-right: 64px;
    }

    .advertisement-link {
        line-height: 24px;
        margin-right: 2px;
    }
}
