@import '../../variables';
@import '~@idexx/spot/dist/styles/shared/color';
@import '../../mixins';

$avatar-icon-size: 112px;
$user-menu-width: 130px;

.container {
    flex: 1 1 auto;
    height: 0;
    flex-direction: column;
}

.profilePageContent {
    display: flex;
    flex-direction: row;
}

.myProfileHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.myProfileTitle {
    order: 0;
}

.editLink {
    order: 1;
    align-content: flex-end;
}

.userIcon {
    order: 1;
    flex: 0 0 auto;
    width: $avatar-icon-size;
    height: $avatar-icon-size;
    display: flex;
    border-radius: 50%;
    border: 3px solid #cbcfd1;
    margin-right: $spot-space-inset-four;
    margin-top: $spot-space-inset-one-and-a-half;

    & > svg {
        color: $spot-color-neutral-91;
        border-radius: 50%;
        padding: 5px 0 0 0;
    }

    & > img {
        border-radius: 50%;
    }

    @include media-breakpoint-up(md) {
        order: 0;
    }
}

.profileInformation {
    flex: 1;
    order: 0;
    display: flex;
    flex-direction: column;
    margin-right: 50px;

    @include media-breakpoint-up(md) {
        order: 1;
        flex-direction: row;
    }
}

.userInfo {
    order: 0;
    flex: 1 1;
    padding-right: $spot-space-inset-one-and-a-half;
}

.accessInfo {
    order: 1;
    flex: 1 1;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    padding-top: $spot-space-inset-one-and-a-half;
}

.userField {
    order: 0;
    @include contentLabel();
}

.fieldValue {
    order: 1;
    word-break: break-all;
}

.nameAndRole {
    display: flex;
    flex-direction: column;
    padding-top: $spot-space-inset-one-and-a-half;
}

.displayName {
    font-weight: 700;
    order: 0;
}

.userRole {
    order: 1;
}
