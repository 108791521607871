.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 20vh;
}

.footer {
    justify-content: flex-end;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: 20px;
    }
}
