.signup-box {
    /*max-width: 450px;*/
    min-width: 600px;
}
.buttons-container {
    text-align: right;
    margin-top: 20px;
}

.signup-button {
    margin-left: 12px;
}
