@import '../../../variables';
@import '~@idexx/spot/dist/styles/shared/color';
@import '../../../mixins';

.siteAccessSection {
    display: flex;
    flex-direction: column;
    border: 1px solid $spot-color-neutral-91;
    border-radius: $spot-space-s;
    margin-bottom: $spot-space-inset-two;
    justify-content: space-between;
}

.accessOption {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > :first-child {
        padding-left: $spot-space-inset-one-and-a-half;
    }
}

.siteAccessImage {
    order: 3;
    height: map-get($base-content-padding, xl);
    margin-top: $spot-space-inset-two;
    margin-right: $spot-space-inset-one-and-a-half;
}

.siteAccessText {
    flex: 2 0;
    padding-left: 0;
    padding-right: $spot-space-inset-two;
}

.siteAccessLabel {
    padding-top: $spot-space-inset-two;
    padding-bottom: 0;
    margin-bottom: $spot-space-s;
}

.siteAccessSubtext {
    padding-top: 0;
    padding-bottom: $spot-space-inset-three-quarter;
    font-size: $spot-font-size-s;
}

.radioButton {
    order: 2;
    flex: 1 auto;
    padding-top: $spot-space-inset-two;
    margin-bottom: 0;
}

.accessDropdown {
    order: 4;
    padding-left: $spot-space-inset-four;
    padding-right: $spot-space-inset-four;
    margin-bottom: $spot-space-inset-one-and-a-half;
}

.accessDetailsDropDown {
    color: $spot-color-teal-35;
}

.accessDetailsText {
    padding-right: $spot-space-inset-one-and-a-half;
}

.accessText {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border: 1px solid $spot-color-neutral-91;
    border-left: 0;
    border-right: 0;
    padding-top: $spot-space-inset-three-quarter;
    padding-bottom: $spot-space-inset-three-quarter;
    margin: -1px;
}

.acccessTypeText {
    flex: 1;
    justify-content: flex-start;
    font-size: $spot-font-size-m;
    padding-right: $spot-space-inset-one;
    margin-bottom: 0;
}

.acccessLevelText {
    margin-left: auto;
    justify-content: flex-end;
    font-size: $spot-font-size-m;
    padding-right: $spot-space-inset-three-quarter;
    color: $spot-color-green-45;
}

.accessDetailsShown {
    order: 4;
    display: block;
    padding-top: $spot-space-inset-two;
}

.accessDetailsHidden {
    display: none;
}
