.masonry {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 0;
}

a {
    cursor: pointer;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

div h1 {
    @extend .spot-typography__heading--level-2;
}

div h2 {
    @extend .spot-typography__heading--level-3;
}

div h3 {
    @extend .spot-typography__heading--level-4;
}

div h4 {
    @extend .spot-typography__heading--level-5;
}

.display-none {
    display: none;
}

.margin-top-1 {
    margin-top: 10px;
}

.margin-top-2 {
    margin-top: 20px;
}

.margin-top-3 {
    margin-top: 30px;
}

.margin-top-4 {
    margin-top: 70px;
}

.margin-left-2 {
    margin-left: 20px;
}

.align-right {
    text-align: right;
}

.float-right {
    float: right;
}

.toast-message-header {
    font-weight: bold;
}

.toast-message-detail {
    font-size: 14px;
}

.Toastify__toast-container {
    z-index: 2147483005; //intercom is 2147483001
    margin: 0;
    left: 50%;
    transform: translate(-50%);
    width: auto;
    max-width: 400px;

    @include media-breakpoint-up(sm) {
        max-width: 600px;
    }
}

.notification-message {
    display: flex;
    overflow-x: auto;
    white-space: pre-wrap;
    color: inherit;
}
