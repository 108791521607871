// Variables
@import '~@idexx/spot/dist/styles/shared/spot_tokens';
@import '~@idexx/spot/dist/styles/shared/color';
@import '~@idexx/spot/dist/styles/shared/util';
@import '~@idexx/spot/dist/styles/shared/typography';
// Primitive Components
@import '~@idexx/spot/dist/styles/components/icon';
@import '~@idexx/spot/dist/styles/components/loading_spinner';
@import '~@idexx/spot/dist/styles/components/container';
@import '~@idexx/spot/dist/styles/components/list_group';
@import '~@idexx/spot/dist/styles/components/toggle';
// Import components that depend on other components later, for example buttons often contain icons,
// so import the button.scss after icon.scss so buttons can override icon styles if necessary
@import '~@idexx/spot/dist/styles/components/avatar';
@import '~@idexx/spot/dist/styles/components/button';
// Uses: Icon
@import '~@idexx/spot/dist/styles/components/cross_product';
@import '~@idexx/spot/dist/styles/components/di';
@import '~@idexx/spot/dist/styles/components/card';
@import '~@idexx/spot/dist/styles/components/dropdown';
@import '~@idexx/spot/dist/styles/components/filter';
@import '~@idexx/spot/dist/styles/components/form';
@import '~@idexx/spot/dist/styles/components/link';
@import '~@idexx/spot/dist/styles/components/long_form_text';
@import '~@idexx/spot/dist/styles/components/paging';
@import '~@idexx/spot/dist/styles/components/patient_display';
@import '~@idexx/spot/dist/styles/components/pill';
@import '~@idexx/spot/dist/styles/components/popover';
@import '~@idexx/spot/dist/styles/components/progress_bar';
@import '~@idexx/spot/dist/styles/components/split_button';
@import '~@idexx/spot/dist/styles/components/tabs';
// Elemental Components
@import '~@idexx/spot/dist/styles/components/data_table';
@import '~@idexx/spot/dist/styles/components/modal';
@import '~@idexx/spot/dist/styles/components/search_bar';
// Uses: form
@import '~@idexx/spot/dist/styles/components/typography';
//----
@import '~@idexx/spot/dist/styles/components/base-card';
@import '~@idexx/spot/dist/styles/components/system_message';

$spot-signin--error-bordercolor: rgba(0, 0, 0, 0.05);
$spot-signin--error-borderradius: 3px;
$spot-signin--error-minheight: 45px;
$spot-signin--error-padding: 4px;
$spot-signin--error-labeltop: 10px;
$spot-signin--error-labelleft: 36px;
$spot-signin--error-iconpadding: 5px;
$spot-signin-height: 40px;
$spot-signin-footer-separator-pad: 4px;
$spot-header-idexx-icon-height: 32px;

.spot-signin {
    @extend %spot-base-card;
    @extend %spot-base-card--small;

    &--borderless {
        @extend %spot-base-card--borderless;
    }

    &--large {
        @extend %spot-base-card--large;
    }
}

.spot-signin__header {
    @extend %spot-base-card__header;
    @extend %spot-base-card__header--large;
}

.spot-signin__header-label {
    @extend %spot-base-card__header-label;
}

.spot-signin__header-image {
    @extend %spot-base-card__header-image;

    &--idexx {
        height: $spot-header-idexx-icon-height;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.spot-signin__content {
    @extend %spot-base-card__content;

    &-intro {
        @include text-body();
        display: inline-block;
        font-weight: $spot-font-weight-bold;
        height: $spot-signin-height;
    }

    &-error {
        &-message {
            @include spot-container-light-primary-parent-selector {
                background-color: $spot-color-red-60;
            }
            @include spot-container-light-secondary-parent-selector {
                background-color: $spot-color-red-60;
            }
            @include spot-container-dark-primary-parent-selector {
                background-color: $spot-color-red-65;
            }
            @include spot-container-dark-secondary-parent-selector {
                background-color: $spot-color-red-65;
            }

            border: 1px solid $spot-signin--error-bordercolor;
            border-radius: $spot-signin--error-borderradius;
            display: table;
            margin: $spot-space-stack-one;
            min-height: $spot-signin--error-minheight;
            padding-bottom: $spot-signin--error-padding;
            padding-top: $spot-signin--error-padding;
            position: relative;

            &-icon {
                display: table-cell;
                fill: $spot-color-white;
                height: $spot-size-icon-s;
                line-height: $spot-font-line-height-default;
                margin-left: $spot-signin--error-iconpadding * 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: $spot-size-icon-s;
            }

            &-label {
                color: $spot-color-white;
                display: table-cell;
                font-size: $spot-font-size-m;
                line-height: $spot-font-line-height-default;
                padding: $spot-signin--error-padding;
                padding-left: $spot-signin--error-labelleft;
                top: $spot-signin--error-labeltop;
                vertical-align: top;
            }
        }
    }

    &-fieldgroup {
        min-height: $spot-form-element-height * 1.5;
        position: relative;
    }

    &--showpassword {
        position: absolute;
        right: 0;
        top: 0;
    }

    &--rememberme {
        bottom: 0;
        left: 0;
        position: absolute;
    }
}

.spot-signin__footer {
    @extend %spot-base-card__footer;

    &-item-separator:before {
        @include spot-container-light-primary-parent-selector {
            color: $spot-text-color-disabled-on-light;
        }
        @include spot-container-light-secondary-parent-selector {
            color: $spot-text-color-disabled-on-light;
        }
        @include spot-container-dark-primary-parent-selector {
            color: $spot-text-color-disabled-on-dark;
        }
        @include spot-container-dark-secondary-parent-selector {
            color: $spot-text-color-disabled-on-dark;
        }

        content: '|';
        line-height: $spot-font-size-m;
        margin-left: $spot-signin-footer-separator-pad;
        margin-right: $spot-signin-footer-separator-pad;
        max-height: $spot-font-size-m;
    }
}

.spot-form__checkbox {
    &.spot-form__checkbox--small {
        .spot-form__checkbox-label {
            margin-bottom: 12px !important;
        }
    }
}

.spot-form__checkbox-input:focus {
    + .spot-form__checkbox-inner {
        box-shadow: none;
    }
}

// SPOT TWEEKS

input,
textarea,
.spot-form__select,
.spot-form__radio-group,
.spot-form__checkbox-group {
    &.error {
        border: 1px solid #ff0000;
    }
}

.spot-button__text {
    margin: auto;
}

svg.spot-form__select-open-icon {
    fill: #33bfcc;
}

.spot-dropdown {
    cursor: pointer;
}

.spot-dropdown__content {
    max-height: 250px;
    padding: $spot-space-inset-three-quarter $spot-space-inset-one;

    &-item {
        padding: $spot-dropdown-content-padding;
        cursor: pointer;

        &--single-line {
            white-space: nowrap;
        }

        &--heading {
            cursor: initial;

            &:hover {
                background-color: inherit;
            }
        }

        &--selected {
            @include spot-container-light-parent-selectors {
                background-color: $spot-interactive-color-hover-on-light-primary;
            }
            @include spot-container-dark-parent-selectors {
                background-color: $spot-interactive-color-hover-on-dark-primary;
            }
        }
    }

    &--divider {
        margin: $spot-space-inset-three-quarter;
        width: unset;
    }
}

.spot-dropdown__caret {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-bottom: 2px;
}

.spot-form__radio-visual--centered {
    align-self: center;
}

.spot-form__radio--full {
    .spot-form__radio-inner {
        width: 100%;
    }
    .spot-form__radio-label {
        width: 100%;
    }
}

.spot-flyover-loading {
    height: 100vh;
    margin-top: 25vh;
    text-align: center;
    color: $spot-text-color-secondary-on-light;

    .spot-loading-spinner {
        margin-bottom: 12px;
    }
}

.spot-search-bar {
    padding: $spot-space-inset-three-quarter 0;
}

.spot-horizontal-rule {
    border-bottom: $spot-border-heavy-on-light-primary;
    margin: 12px 0;
}

.spot-vertical-rule {
    border-left: $spot-border-heavy-on-light-primary;
    margin: 12px 0;
}

.spot-link .spot-link__icon,
.spot-button .spot-button__icon {
    flex-shrink: 0;
    flex-grow: 0;
}

.spot-link:disabled,
.spot-link--disabled {
    .spot-link__icon {
        @include spot-container-light-parent-selectors {
            fill: $spot-text-color-disabled-on-light;
        }

        @include spot-container-dark-parent-selectors {
            fill: $spot-text-color-disabled-on-dark;
        }
    }
}

.spot-link:hover {
    color: $spot-text-color-link-on-light;
}

.spot-link--small {
    font-size: $spot-font-size-s;
}

.spot-link--large {
    font-size: $spot-font-size-l;
}

.spot-search-bar__search-button {
    min-width: auto;
}

.spot-search-bar__search-button {
    min-width: auto;
}

.spot-toggle:disabled {
    &,
    & + .spot-toggle-label {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
