@import '../../../../../variables';
@import '../../../../../mixins';
@import '../../../../../functions';

.holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 47px;
    padding: $spot-space-inset-half $spot-space-inset-three-quarter;
    background-color: $spot-background-color-light-secondary;
    border-bottom: 1px solid $spot-border-control-color-on-light;
}

.leftContainer {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;

    & > *:not(:first-child) {
        margin-top: $spot-space-inset-one;
    }

    @media (min-width: 1200px) {
        display: contents;

        & > *:not(:first-child) {
            margin-top: 0;
        }
    }
}

.item {
    @include text-body();

    color: $spot-interactive-color-on-light !important;
}

.exitButton {
    height: 30px;
    padding-left: $spot-space-inset-half;
    padding-right: $spot-space-inset-half;
    margin-left: -$spot-space-inset-half;
    margin-right: -$spot-space-inset-half;
}

.button {
    height: 30px;
    box-shadow: none !important; // todo: ask proper behaviour
    border: 1px solid $spot-interactive-color-on-light;
    font-weight: 400;

    .buttonIcon {
        opacity: 0.33;
    }

    &:global(.spot-button--active) {
        .buttonIcon {
            opacity: 1;
        }
    }
}

.unselectedButtonIcon {
    opacity: 0.33;
}

.selection {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: $spot-space-inset-three-quarter;
    }
}

.pagination {
    @include text-body();
    font-weight: 600;
    position: absolute;
    right: $spot-space-inset-three-quarter;
    top: $spot-space-inset-three-quarter;

    @include media-breakpoint-up(lg) {
        position: unset;
    }
}

.info {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: $spot-space-inset-one;
    }
}

.columnsButton {
    text-transform: initial;

    &,
    &:hover {
        background-color: transparent;
    }
}

.popper {
    margin-top: 8px !important;
}

.paper {
    padding: $spot-space-inset-one;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-one;
    }
}

.paperTitle {
    @include text-secondary();
    font-size: $spot-font-size-m;
    font-weight: 600;
    margin-bottom: $spot-space-inset-half !important;
}
