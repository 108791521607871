@import '../../../../../variables';
@import '../../../../../../../../node_modules/@idexx/spot/dist/styles/shared/color';
@import '../../../../../mixins';
@import '../../../../../functions';

.bulkUpdateMainFormStyles {
    height: 100%;
}

.bulkUpdateSubFormStyles {
    height: 100%;
}

.bulkUpdateFooterButton {
    white-space: nowrap;
}

.footerStyles {
    display: flex;
    width: 100%;
    justify-content: right;
    flex-direction: column-reverse;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding-right: 90px;
    }
}

.footerStylesSingleButton {
    display: flex;
    width: 100%;
    justify-content: right;
    flex-direction: column-reverse;
    padding-right: 0;

    @include media-breakpoint-up(sm) {
        padding-right: 90px;
        flex-direction: row;
    }
}

.footerStylesSelectPracticeButtons {
    display: flex;
    width: 100%;
    justify-content: right;
    flex-direction: column-reverse;
    padding-right: 0;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        padding-right: 90px;
    }
}

.bulkUpdateCancelButton {
    width: 100%;
    color: $spot-color-teal-35;
    padding-top: 21px;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
        text-align: left;
        padding-left: 175px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 0;
    }
}

.bulkUpdateMainContainerStyleWithDate {
    background: #fff;
    padding-top: 6px;
    overflow: auto;
    height: calc(100% - 270px);

    @include media-breakpoint-up(sm) {
        height: calc(100% - 170px);
    }

    @include media-breakpoint-up(lg) {
        height: calc(100% - 120px);
    }
}

.bulkUpdateCancelButtonText {
    width: 50px;
    cursor: pointer;
    display: contents;

    @include media-breakpoint-up(sm) {
        display: initial;
    }
}

.bulkUpdateCancelButtonSelectPractices {
    width: 100%;
    color: $spot-color-teal-35;
    padding-top: 21px;
    text-align: center;

    @include media-breakpoint-up(sm) {
        text-align: left;
    }
}

.bulkUpdateMainContainerStyleSingleButton {
    background: #fff;
    height: calc(100% - 110px);
    padding-top: 6px;
    overflow: auto;

    @include media-breakpoint-up(sm) {
        height: calc(100% - 70px);
    }
}

.footerButtonStyle {
    margin: 16px 0 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
        margin: 16px 0 0 16px;
        width: auto;
    }

    & > button {
        width: 100%;
    }
}

.footerButtons {
    display: flex;
    text-align: right;
    flex-direction: column-reverse;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.footerButtonsSelectPractices {
    display: flex;
    text-align: right;
    flex-direction: column-reverse;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.bulkUpdateMainContainerStyle {
    background: #fff;
    height: calc(100% - 210px);
    padding-top: 6px;
    overflow: auto;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        height: calc(100% - 110px);
    }

    @include media-breakpoint-up(lg) {
        height: calc(100% - 70px);
    }
}

.bulkUpdateMainContainerStyleSelectPractice {
    background: #fff;
    height: calc(100% - 170px);
    padding-top: 6px;
    overflow: auto;

    @include media-breakpoint-up(sm) {
        height: calc(100% - 70px);
    }
}
