@import '../../../../variables';
@import '../../../../mixins';
@import '../../../../functions';

.columnHeaders {
    align-items: stretch;
    border-bottom: 1px solid $spot-border-control-color-on-light;
}

.columnHeader {
    align-self: stretch;
    background-color: $spot-background-color-light-secondary;

    &:not(:last-of-type) {
        border-right: 1px solid $spot-border-control-color-on-light;
    }
}

.pinnedColumnHeaders {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px -1px rgba(0, 0, 0, 0.12);
}

.pinnedColumns {
    box-shadow: 2px 0px 4px -3px rgba(0, 0, 0, 0.21);
}

.headerContainer {
    display: flex;
    width: 100%;
}

.headerCell {
    @include text-body();
    @include ellipsis();
    font-weight: bold;
}

.titleIcon {
    margin-left: 5px;
    fill: $spot-color-teal-35;
}

.cellContainer {
    display: flex;
    width: 100%;
    max-height: 100%;
}

.cell {
    @include text-body();
    @include ellipsis();
}

//.linkCell {
//    @include spot-link();
//    cursor: pointer;
//}

.groupingCriteriaCell {
    @extend .cell;
    // @extend .linkCell;
}

.row {
    &:hover {
        background-color: $spot-interactive-color-hover-on-light-primary !important;
        cursor: pointer;
    }
}
