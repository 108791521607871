@import '../../variables';
@import '../../mixins';

.enrollmentFrame {
    height: 100%;
    border: none;
    width: 100%;
}

.enrollmentFlyover {
    @include media-breakpoint-up(sm) {
        left: unset;
        width: map-get($grid-breakpoints, sm);
    }
}
