@import '../../../variables';
@import '../../../mixins';

.holder {
    // should be on each page to apply layout padding styles individually on each page
    // should be fixed in the future
    // would require fix in many automation tests` selectors
    @include responsive-content();
}

.pageHeader {
    z-index: 201;
    position: sticky;
}

.wrapper {
    position: relative;
    padding-bottom: $spot-space-inset-one;
}

.subText {
    font-size: 12px;
    color: #001837;
    letter-spacing: 0;
}

.pageFooter {
    // temporary disabled for grid view story
    display: none;
}
