@import '../../variables';
@import '~bootstrap/scss/mixins/breakpoints';

.wrapper {
    padding-bottom: 0;

    @include media-breakpoint-up(xl) {
        padding-left: map-get($base-content-padding, xl);
        padding-right: map-get($base-content-padding, xl);
        max-width: unset;
    }
}

.titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.connectPractice {
    display: flex;
}

.navigation {
    display: flex;
    align-items: center;
    height: 100%;
}

.headerToggle {
    right: 0;
    display: flex;
    margin-left: $spot-space-inset-one-and-a-half;
    color: $spot-text-color-secondary-on-light;

    @include media-breakpoint-up(sm) {
        margin-left: 0;
        position: absolute;
    }
}

.toggle {
    display: flex;

    label {
        margin: 0;
    }
}

.toggleText {
    @include text-secondary();
    white-space: nowrap;
}

.classificationCheckbox {
    float: right;
}
