@import './common';

$card-disabled-color: $spot-text-color-disabled-on-light;

.mapping-ui__card {
    display: flex;
    font-family: $spot-font-family-sans-serif;
    min-height: 110px;
    width: 95%;
    border: 2px solid $spot-color-neutral-81;
    border-radius: 5px;
    background-color: $spot-color-white;

    &--parent {
        border-radius: 5px 5px 0 0;
        border-bottom: none;
    }

    &--child {
        border-top: 1px solid $spot-color-neutral-91;
        border-bottom: 0;
        border-radius: 0;
        min-height: unset;
        transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

        &--final {
            border-radius: 0 0 5px 5px;
            border-bottom: 2px solid $spot-color-neutral-81;
        }

        &--create {
            &:hover {
                background-color: $spot-interactive-color-hover-on-light-primary;
            }

            &-title {
                display: flex;
                align-items: center;
                font-weight: normal;
                color: $spot-color-neutral-60;
            }

            &:hover &-title {
                color: unset;
            }

            &-icon {
                height: $spot-size-icon-l;
                width: $spot-size-icon-l;
                fill: currentColor;
                margin-right: 8px;
            }

            .mapping-ui__card--bottom {
                display: none;
            }
        }

        .mapping-ui__card--content {
            min-height: unset;
        }
    }

    h3 {
        @include level-4-heading();
    }

    &--handle {
        transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        border: 2px solid $spot-color-neutral-81;
        border-right: none;
        border-radius: 3px 0 0 3px;

        &-grip {
            height: 24px;
            fill-opacity: 0.5;
            fill: $spot-color-white;
        }

        &.danger {
            background-color: $spot-color-red-65;
            border-color: $spot-color-red-65;
        }

        &.default {
            background-color: $spot-color-neutral-60;
            border-color: $spot-color-neutral-60;
        }

        &.primary {
            background-color: $spot-color-teal-35;
            border-color: $spot-color-teal-35;
        }

        &.warning {
            background-color: $spot-color-yellow-59;
            border-color: $spot-color-yellow-59;
        }

        &.disabled {
            background-color: $spot-text-color-disabled-on-light;
            border-color: $spot-text-color-disabled-on-light;
        }

        &--parent {
            border-radius: 3px 0 0 0;
        }

        &--child {
            visibility: hidden;
        }
    }

    &--bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: $spot-font-size-m;
        color: $spot-color-neutral-48;
    }

    &--container {
        margin-top: 15px;

        &--child {
            margin-top: 0;
            min-height: unset;
        }
    }

    &--content {
        user-select: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px 10px;
        min-height: 110px;
        width: 100%;
    }

    &--description {
        margin: 0;
        font-size: $spot-font-size-m;
        color: $spot-color-neutral-48;
    }

    &--dragging {
        box-shadow: 0 8px 6px -6px $spot-color-neutral-48;
        z-index: 999;
    }

    &--id {
        top: 10px;
        right: 10px;
        align-self: flex-end;
    }

    &--targeted {
        background-color: $spot-color-teal-87;

        .mapping-ui__card--handle {
            background-color: $spot-color-green-45;
            border-color: $spot-color-green-45;
        }
    }

    &--disabled {
        cursor: not-allowed;

        .mapping-ui__card {
            &--bottom {
                color: $card-disabled-color;
            }
            &--description {
                color: $card-disabled-color;
            }
        }

        h3 {
            color: $card-disabled-color;
        }
    }

    &--title-clickable {
        cursor: pointer;

        h3 {
            color: $spot-color-teal-35;
        }

        &:hover {
            background-color: $spot-interactive-color-hover-on-light-primary;
        }
    }
}
