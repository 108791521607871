@import '../../../variables';
@import '../../../mixins';

:export {
    footerMinHeight: $enterprise-page-footer-height;
}

.wrapperContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.headerContainer {
    flex: 0 0 auto;
    height: $enterprise-page-header-height;
    z-index: 201;
}

.pageHeader {
    position: fixed;
}

.footerContainer {
    min-height: $enterprise-page-footer-height;
    flex: 0 0 auto;
}

.pageFooter {
    position: fixed;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
}

.pageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: $spot-space-inset-two;
    padding-bottom: $spot-space-inset-one;
    width: 100%;
    align-self: center;

    @include responsive-content();
    @include media-breakpoint-up(md) {
        padding-top: 42px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 52px;
        padding-left: 0;
        padding-right: 0;
        max-width: map-get($container-max-widths, xl);
    }
}

.subText {
    font-size: 12px;
    color: #001837;
    letter-spacing: 0;
}
