@import '../../../variables';
@import '../../../mixins';

// We will need adjustments in our global styles and dom to prevent magic numbers (should be flexboxes)
$computed-space-between-header: 207px;

.proTips {
    height: 100%;
    margin-top: 50px;
    overflow: hidden;
    position: relative;
}

.gridDisplayed {
    max-width: 100%;
}

.container {
    height: calc(100vh - #{$computed-space-between-header} - #{$enterprise-page-header-height} - #{map-get($enterprise-header-height, xs)});

    @include media-breakpoint-up(xl) {
        height: calc(100vh - #{$computed-space-between-header} - #{$enterprise-page-header-height} - #{map-get($enterprise-header-height, xl)});
    }

    .actionBar {
        padding: 10px;
        text-align: right;
    }

    .exportCsvButton {
        margin-right: 10px;
    }

    .totalItems {
        float: left;
        padding-top: 10px;
    }
}

.csvInfo {
    padding-right: 30px;
}

.originalPriceCell {
    :global(.ag-header-cell-label) {
        justify-content: flex-end;
    }
}

.originalPriceHeader {
    text-align: end;
}
