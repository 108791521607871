@import '../../../variables';
@import '../../../../../../node_modules/@idexx/spot/dist/styles/shared/color';
@import '../../../mixins';
@import '../../../functions';

$header-height: (
    sm: 100px,
    md: 60px,
);

$row-height: (
    sm: 120px,
    lg: 90px,
);

$avatar-icon-size: 34px;
$edit-user-container-width: 50px;

:export {
    headerXs: strip-unit(map-get($header-height, sm));
    headerSm: strip-unit(map-get($header-height, sm));
    headerMd: strip-unit(map-get($header-height, md));
    headerLg: strip-unit(map-get($header-height, md));
    headerXl: strip-unit(map-get($header-height, md));
    rowXs: strip-unit(map-get($row-height, sm));
    rowSm: strip-unit(map-get($row-height, sm));
    rowMd: strip-unit(map-get($row-height, sm));
    rowLg: strip-unit(map-get($row-height, lg));
    rowXl: strip-unit(map-get($row-height, lg));
}

.headerContainer {
    display: flex;
    align-items: center;

    height: map-get($header-height, lg);

    @include media-breakpoint-up(xl) {
        height: map-get($header-height, xl);
    }
}

.headerContainerName {
    @extend .headerContainer;

    flex: 1;
}

.row {
    &:hover {
        background-color: $spot-interactive-color-hover-on-light-primary !important;
        cursor: pointer;

        .cellEditUser {
            text-decoration: underline;
        }
    }
}

.rowNoHover {
}

.headerCell {
    @include contentLabel();

    text-transform: uppercase;
    flex: 1;
}

.cellContainer {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-height: 100%;
}

.cellContainerMobile {
    @extend .cellContainer;

    align-items: center;
}

.cell {
    white-space: normal;
    color: $spot-text-color-secondary-on-light;
    max-width: 100%;
}

.cellMobile {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 0;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-three-quarter;
    }
}

.cellBlockMobile {
    @extend .cell;

    display: flex;
    flex: 1;
    align-items: center;
}

.cellEditUserMobile {
    flex-basis: auto;
    width: $edit-user-container-width;
}

.cellName {
    @extend .cell;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: $spot-space-inset-one;
    overflow: hidden;
}

.cellEditUser {
    flex: 1;

    display: flex;
    justify-content: flex-end;
    margin: $spot-space-inline-left-quarter;
}

.mobileUserPictureContainer {
    margin-top: -5px;
    margin-bottom: -5px;
}

.mobileUserName {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: $spot-space-inset-one;
}

.mobileEmpty {
    width: $avatar-icon-size;
}

.mobileInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: $spot-space-inset-one;

    & > *:not(:last-child) {
        margin: $spot-space-stack-quarter;
    }
}

.searchBar {
    margin-right: $spot-space-inset-half;
}

.userIcon {
    width: $avatar-icon-size;
    height: $avatar-icon-size;
    color: $spot-color-neutral-91;
    display: flex;
    border-radius: 50%;
    border: 2px solid currentColor;
    background-color: $spot-color-white;

    & > svg {
        color: $spot-color-neutral-91;
        border-radius: 50%;
        padding: 5px 0 0 0;
    }

    & > img {
        border-radius: 50%;
    }
}

.name {
    @include ellipsis();
}

.practiceAccess {
    @include ellipsis();

    color: $spot-text-color-secondary-on-light;
}

.siteAccess {
    @include ellipsis();

    color: $spot-text-color-secondary-on-light;
}

.noRows {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin-bottom: 20px;
    justify-content: flex-end;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-one;
    }

    &.scrollable {
        min-height: initial;
        align-items: center;
        margin-bottom: initial;
    }
}

.noRowsImage {
    height: 200px;
}
