@import '../../../variables';
@import '../../../mixins';

.holder {
    background-color: $spot-background-color-light-secondary;
    min-height: $enterprise-page-footer-height;
    left: 0;
    right: 0;
    position: fixed;
    bottom: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    @include responsive-content();
}
