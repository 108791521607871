@import '../../../variables';
@import '../../../mixins';

.holder {
    width: 100%;
}

.controlPanel {
    align-items: center;
    display: flex;
    flex-direction: column;

    padding-left: $spot-space-inset-one-and-a-half;
    padding-right: $spot-space-inset-one-and-a-half;

    @include media-breakpoint-up(sm) {
        padding-left: 0;
        padding-right: 0;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: flex-end;
    }

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-one;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}

.controlBlock {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-one;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: $spot-space-inset-two;
        }
    }

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
    }
}

.filterBlock {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    flex-direction: column;
    min-width: 0;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-half;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;

        & > *:not(:last-child) {
            margin-right: $spot-space-inset-one-and-a-half;
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        width: 0;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.dropdown {
    cursor: pointer;
    font-size: $spot-font-size-header-level-3;
    color: $spot-color-teal-50;
    fill: $spot-color-teal-50;
    margin-bottom: 0;
}

.dashboardDropdownIcon {
    width: $spot-size-icon-s;
    height: $spot-size-icon-s;
    margin-left: 10px;
}

.filterDropdown {
    @extend .dropdown;
    display: flex;
    justify-content: flex-end;

    font-size: $spot-font-size-header-level-5;

    & > * {
        display: flex;
        flex: 1;
        align-items: center;
        max-width: 100%;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
    }
}

.filterPracticesDropdown {
    @extend .filterDropdown;
    min-width: 0;
}

.timeframeDropdown {
    @extend .filterDropdown;

    flex: 0 0 auto;
}

.filterDropdownItem {
    display: flex;
    overflow: hidden;

    & > * {
        @include ellipsis();
    }
}

.filterDropdownIcon {
    margin-bottom: 0;
    flex: 0 0 auto;
}

.scheduleButton {
    align-self: flex-start;
    flex: 0 0 auto;
}

.spinner {
    padding-top: $spot-space-inset-three-quarter;
    padding-left: $spot-space-inset-one-and-a-half;
    padding-right: $spot-space-inset-one-and-a-half;

    @include media-breakpoint-up(sm) {
        padding-left: 0;
        padding-right: 0;
    }
}

.lookerFrame {
    border: none;
    width: 100%;
}

.looker {
    height: 75vh;
}

.lookerHidden {
    visibility: hidden;
}
