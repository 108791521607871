@import '../../variables';
@import '../../mixins';

// We will need adjustments in our global styles and dom to prevent magic numbers (should be flexboxes)
$computed-space-between-header: 138px;

.master-data-page {
    &.master-data-page {
        padding-bottom: 0;
    }

    .classifications {
        &-header {
            font-size: $spot-font-size-header-level-3;
        }

        &-details {
            font-size: $spot-font-size-s;
            color: $spot-text-color-secondary-on-light;
        }
    }

    .board {
        height: calc(100vh - #{$computed-space-between-header} - #{$enterprise-page-header-height} - #{map-get($enterprise-header-height, xs)});

        @include media-breakpoint-up(xl) {
            height: calc(100vh - #{$computed-space-between-header} - #{$enterprise-page-header-height} - #{map-get($enterprise-header-height, xl)});
        }
    }

    .pro-tips-container {
        margin-top: 50px;
        overflow: hidden;
        position: relative;
    }

    .classifications-database-mapping {
        .cancel-button {
            margin-left: 10px;
        }
    }

    .classification-database-option {
        width: 100%;
        min-height: 80px;
        box-shadow: 0 1px 5px 0 #97999b;
        border-radius: 4px;
        padding: 10px 15px;
        word-break: break-word;

        &--title {
            h2 {
                font-size: $spot-font-size-header-level-3;
            }
        }

        &--description {
            font-weight: 500;
            color: $spot-color-neutral-60;
        }

        &--secondary {
            font-size: 1.2rem;
            line-height: 38px;
        }
    }

    .app-status {
        height: 8px;
        width: 8px;
        background-color: #9da6b2;
        border-radius: 50%;
        display: inline-block;
        flex: 0 0 auto;
        margin-right: 10px;

        &.success {
            background-color: #59b730;
        }

        &.error {
            background-color: #cd202a;
        }

        &.databaseDown {
            background-color: #909090;
        }
    }

    .mapping-file-input {
        border: 0px;
        margin-left: 18px;
        margin-top: -13px;
    }
    .mapping-file-error {
        margin-left: 34px;
    }
}

.classification-details {
    .header {
        margin-top: 10px;
        margin-bottom: 10px;

        .title {
            font-size: $spot-font-size-header-level-3;
            font-weight: $spot-font-weight-light;
            display: inline-block;
        }

        .id {
            margin-left: 10px;
            font-size: $spot-font-size-m;
            font-weight: $spot-font-weight-bold;
        }

        .info {
            font-size: $spot-font-size-m;
            font-weight: $spot-font-weight-bold;
        }

        .details {
            font-size: $spot-font-size-s;
            color: $spot-text-color-secondary-on-light;
        }
    }

    .body {
        height: 100%;

        .content {
            padding-top: 10px;
            margin-left: 20px;
            margin-right: 40px;

            .site-mappings {
                box-shadow: $spot-color-neutral-91 0 2px 4px;
                border: 1px solid $spot-color-neutral-91;
                margin-bottom: 20px;

                .site-description {
                    background: $spot-color-neutral-97;
                    height: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 10px;
                    border-bottom: 1px solid $spot-color-neutral-91;

                    .name {
                        font-size: $spot-font-size-l;
                        font-weight: $spot-font-weight-bold;
                    }

                    .id {
                    }
                }

                .mapping {
                    height: 60px;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 10px;
                    border-bottom: 1px solid $spot-color-neutral-91;

                    .name {
                        font-size: $spot-font-size-m;
                        font-weight: $spot-font-weight-bold;
                    }

                    .empty {
                        color: $spot-text-color-disabled-on-light;
                    }

                    .id {
                        font-size: $spot-font-size-s;
                        margin-left: 10px;
                    }

                    .unlink-icon {
                        cursor: pointer;
                    }
                }
            }

            .details {
                font-size: $spot-font-size-s;
                color: $spot-text-color-secondary-on-light;
                padding-bottom: 10px;
            }
        }

        .letter-bar {
            position: fixed;
            top: 170px;
        }
    }

    .editable-fields {
        margin: 0 -0.125rem;
        padding: $spot-space-m;

        .row:first-child {
            margin-bottom: $spot-space-m;
        }

        .footer {
            background: $spot-color-neutral-97;
            padding: $spot-space-m;

            .spot-button--link {
                padding-left: 0;
                padding-right: 0;
            }

            .col {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
