@import 'controlCenter/ControlCenterPage_Legacy';
@import 'masterData/MasterData';

.page-wrapper {
    .spot-tabs__list-wrap {
        border-bottom: solid 1px #d8d8d8;
    }

    .add-new-item-container {
        position: absolute;
        top: 20px;
        right: 0;
    }

    .add-new-item-icon {
        width: 32px;
        height: 32px;
        fill: #00a7b5;
        margin-left: 7px;
    }
}
