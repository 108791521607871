@import '../../../../../variables';

.container {
    display: flex;

    & :global(.spot-form__field-group) {
        align-items: flex-end;
    }
}

.leftPanel {
    padding-left: $spot-space-inset-one;
    padding-right: $spot-space-inset-two;
}

.rightPanel {
    border-left: $spot-border-light-on-light-primary;
    padding-left: $spot-space-inset-two;
    padding-right: $spot-space-inset-one;
    color: $spot-text-color-primary-on-light;
}

.formGroup {
    justify-content: space-between;
    margin: 0;
}

.halfWidth {
    width: 50%;
}

.block {
    &:not(:last-of-type) {
        padding-bottom: $spot-space-inset-one-and-a-half;
    }

    &:not(:first-of-type) {
        padding-top: $spot-space-inset-one;
    }
}

.fieldPrefix {
    height: 40px;
    align-items: center;
    display: flex;
    position: relative;
    width: 0;
    left: -5px;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.checkbox {
    margin-bottom: 0;
}

.toggleLabel {
    font-size: 15px;
    padding-bottom: 10px;
}
