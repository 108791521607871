@import '../../../../variables';
@import '~bootstrap/scss/mixins/breakpoints';

.holder {
    display: flex;
    flex: 1;
}

.container {
    display: flex;
    flex-flow: column;
    padding-right: 0;
    padding-left: 0;
}

.grid {
    flex: 1;
    margin-left: -(map-get($base-content-padding, xs));
    margin-right: -(map-get($base-content-padding, xs));
    width: auto;

    @include media-breakpoint-up(sm) {
        margin-left: unset;
        margin-right: unset;
    }
}
