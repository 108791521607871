@import './common';

.mapping-ui__column {
  position:relative;
  display: inline-block;
  font-family: $spot-font-family-sans-serif;
  height: 100%;
  width: 530px;
  padding: 15px;
  box-sizing: border-box;

  .horizontal-rule {
    width: 100%;
    height: 1px;
    border-bottom: 2px solid $spot-color-neutral-81;
    box-shadow: 0 2px 2px -1px $spot-color-neutral-81;
    margin: 10px 0 0 0;
  }

  &:not(:last-child) {
    border-right: 2px solid $spot-color-neutral-81;
  }

  h5 {
    @include level-5-heading();
    font-size: $spot-font-size-l;
  }

  &--collection-char {
    font-size: $spot-font-size-s;
    font-weight: $spot-font-weight-bold;
    margin: 10px 5px;
  }

  &--droppable {
    &-addition {
      background-color: $spot-color-teal-93;
    }
  }

  &--letter-bar {
    width: 40px;
    font-size: $spot-font-size-s;
    font-weight: $spot-font-weight-bold;
    align-items: center;
    position: absolute;
    top: 70px;
    right: 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        cursor: pointer;
        color: $spot-color-teal-35;
        z-index: 1;
      }
    }
  }

  &--scrollable {
    height: calc(100% - 30px);
    width: 100%;

    .os-host {
      height: 100%;
    }

    .os-scrollbar-handle {
      background: $spot-color-neutral-81!important;
    }

    [data-react-beautiful-dnd-droppable] {
      min-height: 100%;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--actions {
      display: flex;
      &-menu-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        fill: $spot-color-teal-35;
      }

      &-menu-item {
        display: flex;
        align-content: center;
        justify-content: center;
        cursor: pointer;

        svg {
          margin-right: 5px;
          width: $spot-size-icon-l;
          height: $spot-size-icon-l;
        }

        &.danger {
          color: $spot-color-red-65;
          fill: $spot-color-red-65;
        }

        &.default {
          color: $spot-color-neutral-60;
          fill: $spot-color-neutral-60;
        }

        &.primary {
          color: $spot-color-teal-35;
          fill: $spot-color-teal-35;
        }

        &.warning {
          color: $spot-color-yellow-59;
          fill: $spot-color-yellow-59;
        }

        &.disabled {
          color: $spot-text-color-disabled-on-light;
          fill: $spot-text-color-disabled-on-light;
        }
      }

      .spot-popover-wrapper {
        position: relative;
      }

      .spot-popover {
        text-align: center;
        font-size: $spot-font-size-l;
        white-space: nowrap;
      }
    }

    &--title {
      display: inline-flex;
    }
  }
}

// Resolves OS-Scrollbar and React-Beautiful-DND z-index issues
.os-host-overflow > .os-padding {
  z-index: auto;
}

