@import '../../../variables';
@import '../../../mixins';

$avatar-icon-size: 34px;
$user-menu-width: 130px;

.holder {
    position: sticky;
    top: 0;
    z-index: 202;
    color: $spot-background-color-dark-primary;
    background-color: $spot-background-color-light-primary;
    border-bottom: 6px solid $spot-color-teal-35;

    @include set-responsive-parameter-up(height, $enterprise-header-height);
}

.navBar {
    padding: 0;
    justify-content: space-between;
    display: flex;
    height: 100%;
}

.content {
    justify-content: space-between;
    display: flex;
    flex: 1;
    height: 100%;

    @include responsive-content();
}

.leftBlock {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}

.rightBlock {
    color: $spot-interactive-color-on-light;
    display: flex;
    align-items: center;
    position: relative;
}

.title {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;

    & > * {
        color: $spot-background-color-dark-primary;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .selectedItem {
        @include media-breakpoint-up(sm) {
            font-size: 20px;
            line-height: 20px;
        }
    }
}

.logo {
    height: 28px;
    width: 130px;
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.toggleButton {
    margin-right: 15px;
    box-shadow: none;
    padding: 0;
    border: 0;
    border-radius: 0;

    &:focus {
        border: 0;
        outline: none;
    }

    &:hover {
        color: $spot-interactive-color-on-light;
        fill: $spot-interactive-color-on-light;
    }

    & > * {
        margin-top: 1px;
    }
}

.menu {
    padding: 7px 0;
    background-color: $spot-background-color-dark-primary;
    order: 1;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        background-color: transparent;
        position: absolute;
        order: 0;
        left: 0;
        right: 0;
    }
}

.navigationItem {
    color: $spot-text-color-primary-on-dark;
    font-size: 20px;
    font-weight: $spot-font-weight-normal;
    padding: 8px 25px;
    outline: none;

    &:hover {
        color: $spot-interactive-color-on-dark;
    }

    @include media-breakpoint-up(lg) {
        font-size: 18px;
        letter-spacing: 0.03em;
        text-align: center;

        &,
        &:hover {
            color: $spot-text-color-primary-on-light;
            text-decoration: none;
        }

        &:before {
            display: block;
            content: attr(data-label);
            font-weight: 700;
            height: 0;
            overflow: hidden;
            visibility: hidden;
            letter-spacing: normal;
        }
    }
}

.navigationItemActive {
    color: $spot-color-neutral-97;
    position: relative;
    letter-spacing: normal;

    @include media-breakpoint-up(lg) {
        font-weight: 700;
        color: $spot-text-color-primary-on-light;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        bottom: 7px;
        width: 5px;
        background-color: $spot-color-neutral-97;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.navigationIcon {
    color: $spot-color-teal-35;
    width: $spot-size-icon-l;
    height: $spot-size-icon-l;
    padding: 8px;
    display: flex;
    border-radius: 50%;
    position: relative;
    box-sizing: content-box;

    @include media-breakpoint-up(xl) {
        width: $spot-size-icon-l;
        height: $spot-size-icon-l;
        padding: 10px;
    }

    &:not(:first-child) {
        margin: $spot-space-inline-right-three-quarter;
    }

    &:hover {
        color: $spot-color-teal-35;
        box-shadow: inset 0 0 0 2px $spot-color-neutral-91;
    }
}

.navigationIconActive {
    background-color: $spot-color-neutral-91;
}

.navigationIconNotification {
    position: absolute;
    top: -2px;
    right: 6px;
    padding: 6px;
    margin: 0 !important;
    transform: translateX(50%);
    border-color: $spot-background-color-light-primary;

    @include media-breakpoint-up(xl) {
        top: 0;
    }
}

.dropdownTitle {
    display: flex;
    align-items: center;
}

.userInfoBlock {
    max-width: 125px;
    display: flex;
    align-items: center;
    box-sizing: content-box;

    fill: $spot-color-neutral-28;
    color: $spot-color-neutral-91;

    &:hover {
        color: $spot-interactive-color-on-light;
        fill: $spot-interactive-color-on-light;
    }
}

.userInfo {
    margin-right: 5px;
    display: none;

    @include media-breakpoint-up(xl) {
        display: block;
    }
}

.userIcon {
    width: $avatar-icon-size;
    height: $avatar-icon-size;
    display: flex;
    border-radius: 50%;
    border: 2px solid currentColor;

    & > svg {
        color: $spot-color-neutral-91;
        border-radius: 50%;
        padding: 5px 0 0 0;
    }

    & > img {
        border-radius: 50%;
    }
}

.menuUserBlock {
    max-width: $user-menu-width;
    margin-top: 5px;
    margin-bottom: 5px;
    .userInfoText {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.userInfoContainer {
    display: flex;
    align-items: center;

    &::before {
        content: '';
        margin-left: $spot-space-inset-three-quarter;
        height: 1.9em;

        @include media-breakpoint-up(xl) {
            padding-left: $spot-space-inset-one-and-a-half;
            margin-left: $spot-space-inset-one-and-a-half;
            border-left: $spot-border-heavy-on-light-primary;
        }
    }
}
