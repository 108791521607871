@import '../../variables';
@import '~@idexx/spot/dist/styles/shared/color';
@import '../../mixins';
@import '../../functions';

.holder {
    padding-bottom: 0;
    margin-bottom: -1px;
}

.container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.practicesBlock {
    display: flex;
    flex-direction: column;
}

.titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.connectPractice {
    display: flex;
}

.grid {
    flex: 1;
}

.notificationAnalytics {
    fill: $spot-color-yellow-59;
    font-weight: bold;
}

.practicesEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 90px;

    > *:not(:first-child) {
        margin-top: 25px;
        margin-bottom: 0;
    }
}

.practicesEmptyImage {
    height: 190px;
}

.practicesEmptyText {
    @include spot-text-color-secondary();

    width: 250px;
    text-align: center;
}
