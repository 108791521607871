.item-card-details {
    margin-top: 20px;
    border-top: 1px solid #d8d8d8;

    > div {
        margin-top: 20px;
    }

    .card-holder {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .app-info {
        width: 200px;
        height: 100%;
        padding: 5px;

        .status {
            position: absolute;
            bottom: 0;
        }
    }

    .subProductMessage {
        display: flex;
        flex-direction: column;
    }

    .text {
        order: 0;
    }

    .subtext {
        order: 1;
        font-size: 0.9rem;
        color: $spot-color-neutral-60;
    }

    .site-name {
        margin-bottom: 22px;
    }

    p {
        margin-left: 15px;
    }

    .practice-pill-container {
        flex-direction: row;
        .practice-pill-buttons {
            display: contents;
            .practice-pill-button-text {
                width: max-content;
            }
        }
        .add-additional-clinics {
            a {
                margin-top: 4px;
                width: max-content;

                .clinic-list-add-icon {
                    width: 25px;
                    height: 25px;
                    fill: #00a7b5;
                    margin-left: 5px;
                    padding-right: 4px;
                }
            }
        }
    }

    @media only screen and (max-width: 599px) {
        .practice-pill-container {
            flex-direction: column;
            .practice-pill-buttons {
                display: unset;
            }
        }
    }
}
