$breakpoint-desktop: 992px;

// Resetting default styles
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
}

.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  max-width: 900px;
  list-style-type: none;
  text-align: center;

  @media (max-width: 991px) {
    padding-right: 60px;
    padding-left: 60px;
  }

  &--active {
    display: block;
  }
}

.carousel-slide__content {
  margin-bottom: 19px;

  @media (max-width: $breakpoint-desktop - 1px) {
  }
}

.carousel-slide__author,
.carousel-slide__source {
  @media (min-width: $breakpoint-desktop) {
  }
}

.carousel-slide__source {
  color: #888;
}

// Carousel arrows
.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #111;
  cursor: pointer;
  opacity: .75;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: .5;
  }

  &--left {
    left: 32px;
  }

  &--right {
    right: 32px;
  }
}

// Carousel indicators
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
}

.carousel__indicator {
  height: 15px;
  width: 15px;
  border: 1px solid #00A7B5;
  border-radius: 50%;
  display: inline-block;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:hover {
    opacity: .5;
    background-color: #00A7B5;
  }

  &--active {
    background-color: #00A7B5;
    &,
    &:hover {
      opacity: .75;
    }
  }
}
