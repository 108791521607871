.marketing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 -15px;

  p {
    @extend .spot-typography__text--body;
  }

  .row {
    margin: 0;
  }

  .marketing-blurb {
    display: flex;
    justify-content: center;

    @media (min-width: 1920px) {
      p {
        max-width: 50%;
      }
    }
  }

  .navbar {
    padding: 15px 24px;

    .navbar-collapse {
      text-align: right;
    }

    .idexx-logo {
      height: 30px;
      width: 150px;
    }

    .sign-up {
      @extend .spot-link;
      @extend .spot-link--large;
      color: $spot-color-white;
      margin-right: 24px;
      font-weight: 900;

      &:hover, &:visited {
        color: $spot-color-white;
      }
    }

  }

  .marketing-panel--top {
    background-image: linear-gradient(72deg, $spot-color-teal-35, $spot-color-teal-50);
    padding-bottom: 30px;

    .tag-line, .info-block {
      color: $spot-color-white;
    }

    .info-block {
      font-size: 125%;
      margin: 15px 0 30px 0;
    }
  }

  .marketing-panel {
    flex-grow: 1;
    padding: 30px 0;

    p {
      @extend .spot-typography__heading--level-5;
    }

    h2 {
      margin-bottom: 15px;
    }

    .marketing-panel--card {
      display: flex;
      align-items: center;
      align-content: flex-start;
      padding: 40px;
      text-align: left;

      h3 {
        text-transform: uppercase;
      }

      .marketing-panel--card-body {
        margin-left: 40px;
        width: 250px
      }
    }

    .icon-ball {
      background-image: linear-gradient(72deg, $spot-color-teal-35, $spot-color-teal-50);
      padding: 30px;
      border-radius: 100%;
      color: white;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg.icon {
        width: 42px;
        height: 42px;
        fill: $spot-color-white;

        .invert-path {
          fill: $spot-color-teal-50;
        }

        .add-stroke {
          stroke: $spot-color-teal-50;
          stroke-width: .5;
        }
      }
    }

    .marketing-notice {
      div {
        padding: 0;
      }
      p {
        color: $spot-color-neutral-74;
        font-size: 80%;
        text-align: center;
      }
    }
  }

  .marketing-panel--end {
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $spot-color-neutral-97;
    padding: 40px 0;

    .terms {
      font-size: 80%;
    }
  }

}

@media only screen and (max-width: 768px) {
  .marketing-panel--card {
    p {
      font-size: 80%!important;
    }

    h3 {
      font-size: 90%!important;
    }
  }
}
