@import './common';

.mapping-ui__stack-drop-down {
  @include level-5-heading();
  font-size: $spot-font-size-l;
  border: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  cursor: pointer;
  padding-right: 25px;

  &:focus {
    outline: none;
  }

  &--caret-down {
    fill: $spot-color-neutral-60;
    margin-left: -20px;
    pointer-events: none;
  }
}
