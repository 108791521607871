@import '../../../variables';
@import '../../../mixins';
@import '~@idexx/spot/dist/styles/shared/spot_tokens';

.analytics-page {
    .content {
        margin-top: 32px;

        @include media-breakpoint-up(sm) {
            margin-top: 42px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 52px;
        }
    }
}

.industryBenchmarks {
    max-width: 650px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        max-width: 1500px;
    }

    & .industryBenchmarks {
        text-align: center;
        padding-left: 3.75rem;
        padding-right: 3.75rem;

        & > div {
            padding: 12px 24px;
            display: flex;
            flex-direction: column;
            align-content: space-around;
            justify-content: center;
            align-items: center;
        }

        & h3 {
            margin-top: 10px;
            line-height: 24px;
        }

        & p {
            margin-top: 10px;
        }
    }
}

.lookerTitle {
    font-size: 34px;
    font-weight: 100;
    line-height: 1.4em;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.demoAnalyticsWrapper {
    margin-top: 50px;

    &:first-child .looker-frame {
        height: 1250px;

        @include media-breakpoint-up(lg) {
            height: 950px;
        }
    }
}

.buckets {
    width: 150px;
    @include media-breakpoint-up(xl) {
        width: 200px;
    }
}

.compare {
    width: 285px;
    @include media-breakpoint-up(xl) {
        width: 380px;
    }
}

.industryBenchmarksBanner {
    margin: 0 24px 32px 24px;

    &.priorityHighTeal {
        background-color: $spot-color-teal-50;
        border-color: $spot-color-teal-50;

        & > .tealIcon {
            background-color: $spot-color-teal-35;
        }
    }
}

.bannerHeader {
    color: #fff;

    @include media-breakpoint-up(lg) {
        display: inline;
    }
}

.connectPractice {
    text-decoration: none;
    margin-top: 8px;

    &:hover {
        text-decoration: none;
        color: $spot-color-teal-35;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 20px;
        margin-top: auto;
    }
}

.connectionDetails {
    color: #fff;
    text-decoration: underline;

    @include media-breakpoint-up(lg) {
        padding-left: 20px;
    }
}
