@import '../../../../../variables';
@import '../../../../../mixins';
@import '../../../../../functions';

.headerMenuCustomItemContainer {
    padding-top: $spot-space-inset-half;
    padding-bottom: 10px;
}

.headerMenuCustomItem {
    padding: 6px $spot-space-inset-one;
    cursor: pointer;
    &:hover {
        background-color: $spot-background-color-light-secondary !important;
        cursor: pointer;
    }
    &.disabled {
        color: $spot-text-color-disabled-on-light;
        pointer-events: none;
    }
}
