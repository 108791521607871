@import '../../variables';
@import '~@idexx/spot/dist/styles/shared/color';
@import '../../mixins';
@import '../../functions';

.holder {
    padding-bottom: 0;
    margin-bottom: -1px;
}

.container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.usersBlock {
    display: flex;
    flex-direction: column;
}

.titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.connectUser {
    display: flex;
    // todo: remove when implement edit-user/add new user feature
    visibility: hidden;
}

.grid {
    flex: 1;
}
