@import '../../../variables';
@import '~@idexx/spot/dist/styles/shared/color';
@import '../../../mixins';

.holder {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-bottom: 40px;
    border: 1px solid $spot-color-neutral-91;
    padding: $spot-space-inset-one-and-a-half;
}

.holderError {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: $spot-space-inset-one-and-a-half;
    margin-bottom: 5px;
    border: 2px solid $spot-color-red-73;
}

.containerMain {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
        margin-top: $spot-space-inset-one;
    }

    & > *:not(:first-child) {
        margin-left: $spot-space-inset-one;
    }
}

.grid {
    margin-top: $spot-space-inset-one;
}

.titleBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-half;
    }
}

.titleDescription {
    &:empty:before {
        content: '\200b'; // unicode zero width space character
    }
}

.imageBlock {
    flex-basis: 84px;
}

.image {
    max-height: 100%;
    max-width: 100%;
}

.button {
    padding: 0;
    margin-top: $spot-space-inset-half;

    &:focus {
        box-shadow: none;
    }
}

.caret {
    transition: 0.05s ease-in-out;

    &.expanded {
        transform: rotate(90deg);
    }
}

.accessTypeSelect {
    & > *:not(:first-child) {
        margin-left: $spot-space-inset-one-and-a-half;
    }
}

.configurePractices {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $spot-space-inset-two;
    padding-bottom: $spot-space-inset-one;

    & > * {
        margin: $spot-space-inset-squish-quarter;
    }
}

.configurePracticeText {
    color: $spot-color-neutral-60;
}

.myOrganization {
    text-transform: capitalize;
}

.incompletePracticeAccessMessage {
    font: $spot-font-family-sans-serif;
    font-weight: $spot-font-weight-bold;
    font-size: $spot-font-size-metadata;
    margin-bottom: 40px;
    padding-left: $spot-space-inset-half;
    background-color: $spot-color-sales-red-96;
    color: $spot-color-red-73;
}
