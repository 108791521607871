@import '../../../variables';
@import '../../../mixins';

.mainLayout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    flex: 0 0 auto;
    z-index: 202;

    @include set-responsive-parameter-up(height, $enterprise-header-height);
}

.headerContainer {
    position: fixed;
    width: 100%;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
}
