.footer {
    justify-content: flex-end;
    align-items: center;
}

.stayButton {
    width: auto !important;
    margin: 0 !important;
}

.leaveButton {
    width: auto !important;
    margin: 0 0 0 20px !important;
}
