@import '../../../mixins';
@import '../../../variables';

.activityPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 0;
    padding-right: 0;
}

.grid {
    flex: 1;
    margin-left: -(map-get($base-content-padding, xs));
    margin-right: -(map-get($base-content-padding, xs));
    width: auto;

    @include media-breakpoint-up(sm) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    & :global {
        p {
            margin: unset;
        }
    }
}

.status {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 5px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.statusPill {
    display: flex;
    width: 75px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.taskErrorMessage {
    background-color: $spot-background-color-light-secondary;
    border: 0;
}

.activityRow {
    flex: 1;
    width: 0;
    display: block;
}

.rowItem {
}

.activityStatusRow {
    flex: 1;
}

.activityDateRow {
    display: inline-block;
}

.bulkUpdateDetailsPageHeader {
    margin-top: 16px;

    .title {
        margin-bottom: 3px;
    }

    .itemsCount {
        margin-bottom: 7px;
    }
}

.bulkUpdateDetailsText {
    padding: 20px;
}

.date {
    margin: 0;
    color: $spot-text-color-secondary-on-light;

    @include media-breakpoint-up(lg) {
        color: initial;
    }
    min-width: 275px;
}

.action {
    display: inline-block;
    padding-top: 4px;
    margin-bottom: -8px;
}

.name {
    @include ellipsis();
}

.detailsRow {
    &:not(:last-of-type) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $spot-border-control-color-on-light;
    }
}

.searchBar {
    padding-top: $spot-space-inset-one-and-a-half;
}

.detailsBody {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.detailsContent {
    flex: 1;
    overflow: auto;
}

.paginationFooter {
    background-color: white;
    border-top: $spot-border-light-on-light-primary;

    p {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.paginationFooterRoot {
    display: inline-flex;
}

.headerCell {
    @include text-body();
    @include ellipsis();
    font-weight: bold;
}

.headerContainer {
    display: flex;
}

.columnHeadersInner {
    background-color: $spot-background-color-light-secondary;
}
.columnHeader {
    &:not(:last-of-type) {
        border-right: 1px solid $spot-border-control-color-on-light;
    }
}

.columnHeaders {
    background-color: $spot-background-color-light-secondary;
}

.columnSeparator {
    display: none;
}
