@import '../../../variables';

.holder {
    position: fixed;
    bottom: $enterprise-page-footer-height + 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
    right: 0;
}

.assignRegion {
    background-color: $spot-interactive-color-on-light;
    bottom: 0;
    padding: 20px 50px;
    border-radius: $spot-border-radius-m;
    box-shadow: #000000 0 0 4px;
}

.createRegion {
    position: absolute;
    width: 500px;
    height: 300px;
}

.title {
    color: $spot-color-white;
    fill: $spot-color-white;
    font-size: $spot-font-size-form-label;
}

.dropdown {
    ul {
        bottom: 30px;
    }
}
