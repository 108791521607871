@import '../../../variables';

.holder {
    height: 100%;
    display: flex;
}

.container {
    flex-wrap: nowrap;
}

.tab {
    height: 100%;
}

.navLink {
    height: 100%;
    display: flex;
    font-size: $spot-font-size-header-level-4;

    &:hover {
        text-decoration: none;
    }
}
