@import '../../../../../variables';

.header {
    margin-bottom: $spot-space-inset-three-quarter;
}

.link.link {
    margin-left: -$spot-space-inset-three-quarter;
    margin-bottom: $spot-space-inset-half;

    &:disabled,
    &[disabled] {
        color: $spot-text-color-secondary-on-dark;

        svg {
            fill: $spot-text-color-secondary-on-dark;
        }
    }
}
