@import './common';

.mapping-ui__board {
    display: flex;
    height: 100%;

    &--disabled {
        opacity: 0.7;
        cursor: not-allowed;

        & * {
            pointer-events: none;
        }
    }
}

.mapping-ui__droppable {
    &--full-width {
        display: flex;
        width: 100%;
    }
}
