@import '../../../variables';
@import '../../../mixins';
@import '~bootstrap/scss/mixins/breakpoints';

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #808080;
    opacity: 0.5;
    z-index: 99;
}

.modal {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #eaeff1;
    z-index: 200;
    transition: 0.3s ease-out;
    display: flex;
    flex-direction: column;

    /* mobile first approach */
    left: 0;
    margin-top: $enterprise-page-header-height;

    // We will need adjustments in our global styles and dom to prevent magic numbers (should be flexboxes)
    @include set-responsive-parameter-up(top, $enterprise-header-height);

    @include media-breakpoint-up(md) {
        left: 30%;
    }

    &.smallModal {
        @include media-breakpoint-up(xl) {
            left: 60%;
        }
    }
}

.header {
    border-bottom: none;
    margin-left: 2px;
    margin-bottom: 2px;
    padding: 0 24px 0 0;
    background-color: $enterprise-header-background-color;
    flex: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.title {
    margin: 10px;
    padding: 0 0.125rem;
    line-height: 1.5;

    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
        margin-bottom: 0;
    }
}

.closeContainer {
    position: absolute;
    right: 24px;
    z-index: 202;
    padding: 10px;
    line-height: 1rem;
}

.close {
    margin-top: -0.0416667rem;
    margin-right: -0.208333rem;
    font-size: 14px;
    font-weight: 400;
    // line-height: 1rem;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.body {
    // This doesn't do much, but at least with several paragraphs in the content
    // it doesn't mess up the modal's proportions.
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0 0.125rem;
    position: relative;
    flex: 1 1 auto;

    & > :first-child {
        margin-top: 0;
    }
    & > :last-child {
        margin-bottom: 0;
    }
}
