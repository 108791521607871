/* open-sans-300 */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('OpenSans-Light.ttf') format('truetype');
}
/* open-sans-300italic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('OpenSans-LightItalic.ttf') format('truetype');
}
/* open-sans-italic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('OpenSans-RegularItalic.ttf') format('truetype');
}
/* open-sans-regular */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('OpenSans-Regular.ttf') format('truetype');
}
/* open-sans-600 */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('OpenSans-SemiBold.ttf') format('truetype');
}
/* open-sans-600italic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('OpenSans-SemiBoldItalic.ttf') format('truetype');
}
/* open-sans-700 */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('OpenSans-Bold.ttf') format('truetype');
}
/* open-sans-700italic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('OpenSans-BoldItalic.ttf') format('truetype');
}
/* open-sans-800 */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('OpenSans-ExtraBold.ttf') format('truetype');
}
/* open-sans-800italic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url('OpenSans-ExtraBoldItalic.ttf') format('truetype');
}
