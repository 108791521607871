.item-edit-page {
    .header {
        margin-bottom: 5px;

        h1 {
            display: inline-block;
            margin-left: 14px;
        }

        .variants-switcher {
            display: block;
            margin-left: 16px;
            margin-bottom: 16px;
            margin-right: 16px;
            border-bottom: solid 1px #d8d8d8;
            a {
                margin-right: 10px;
                &.active {
                    border-bottom: 4px solid #000;
                }
            }
        }
    }

    .headerFields {
        margin-left: 14px;

        .copy-description-link {
            margin: $spot-space-inline-right-quarter;
            cursor: pointer;
        }
    }

    .errorMessage {
        color: #f9423a;
    }
}

.add-remaining-clinics {
    display: block;

    .clinic-list {
        span {
            color: $spot-color-neutral-60;
            margin-right: 15px;
        }
    }

    a {
        margin-left: 10px;

        .clinic-list-add-icon {
            width: 16px;
            height: 16px;
            fill: #00a7b5;
            margin-left: 5px;
        }
    }
}
