@import '../../../../variables';
@import '../../../../../../../node_modules/@idexx/spot/dist/styles/shared/color';
@import '../../../../mixins';
@import '../../../../functions';

$header-height: (
    sm: 60px,
);

$row-height: (
    sm: 90px,
    xl: 70px,
);

$avatar-icon-size: 34px;
$edit-user-container-width: 50px;

:export {
    headerXs: strip-unit(map-get($header-height, sm));
    headerSm: strip-unit(map-get($header-height, sm));
    headerMd: strip-unit(map-get($header-height, sm));
    headerLg: strip-unit(map-get($header-height, sm));
    headerXl: strip-unit(map-get($header-height, sm));
    rowXs: strip-unit(map-get($row-height, sm));
    rowSm: strip-unit(map-get($row-height, sm));
    rowMd: strip-unit(map-get($row-height, sm));
    rowLg: strip-unit(map-get($row-height, sm));
    rowXl: strip-unit(map-get($row-height, xl));
}

.holder {
    borderbottom: none;
}

.headerContainerName {
    display: flex;
    align-items: center;
    flex: 1;

    height: map-get($header-height, lg);

    @include media-breakpoint-up(xl) {
        height: map-get($header-height, xl);
    }
}

.row {
    &:hover {
        background-color: $spot-interactive-color-hover-on-light-primary !important;
    }
}

.headerCell {
    @include contentLabel();

    text-transform: uppercase;
    flex: 1;
}

.cellContainer {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-height: 100%;
}

.cellName {
    white-space: normal;
    color: $spot-text-color-secondary-on-light;
    max-width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-half;
    }

    @include media-breakpoint-up(xl) {
        flex-direction: row;
    }
}

.info {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.text {
    flex: 1;

    @include ellipsis();
    color: $spot-text-color-secondary-on-light;
}

.searchBar {
    margin-right: $spot-space-inset-half;
    margin-left: $spot-space-inset-half;
}

.searchBarContainer {
    background-color: $spot-background-color-light-secondary;
}

.name {
    flex: 1;

    @include ellipsis();
}

.noRows {
    display: flex;
    flex-direction: column;

    padding-top: map-get($header-height, sm);
}

.gridTitles {
    padding-top: 15px;
    font-size: $spot-font-size-s;
    color: $spot-text-color-secondary-on-light;
    text-transform: capitalize;
    letter-spacing: 1.5px;
}

.noPracticesFound {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 325px;
}

.noPracticesImage {
    position: absolute;
    width: 150px;
}

.noPracticesText {
    position: absolute;
    top: 85%;
    font-size: 22px;
    color: $spot-color-neutral-60;
}

.practicesMainContainer {
    height: 80%;
    max-height: 800px;
}
