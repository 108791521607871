@import '~@idexx/spot/dist/styles/shared/spot_tokens';
@import '~@idexx/spot/dist/styles/shared/util';
@import '~@idexx/spot/dist/styles/shared/typography';
@import '~@idexx/spot/dist/styles/shared/color';

$enterprise-header-background-color: $spot-color-white;

$enterprise-header-height: (
    xs: 64px,
    xl: 64px,
);

$enterprise-page-header-height: 52px;
$enterprise-page-footer-height: 52px;

// Bootstrap Variables override

$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 768px,
    lg: 1024px,
    xl: 1440px,
) !default;

$container-max-widths: (
    sm: 564px,
    md: 720px,
    lg: 980px,
    xl: 1400px,
) !default;

// End-of: Bootstrap Variables override

$base-content-padding: (
    xs: 24px,
    sm: $spot-space-l,
    md: $spot-space-l,
    lg: 50px,
    xl: 75px,
);
