.actionHeader {
    height: 45px;
    padding-top: 9px;
    margin-bottom: 10px;
    background-color: #33bfcc;

    svg {
        fill: #fff;
    }
}

.actionsListTitle {
    color: #fff !important;
    cursor: pointer;
}
