@import '../../variables';
@import '~@idexx/spot/dist/styles/shared/color';
@import '../../mixins';

$avatar-icon-size: 86px;
$avatar-image-size: 80px;
$small-footer-height: 130px;

.holder {
    display: flex;
    flex-direction: column;
    z-index: 0;
    padding-bottom: $spot-space-inset-two;
}

.form {
    display: flex;
    width: 100%;
}

.errorMessage {
    margin-top: $spot-space-inset-one-and-a-half;
}

.container {
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: initial;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.editProfilePageContent {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.userInformation {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    min-width: 300px;

    @include media-breakpoint-up(md) {
        padding-right: $spot-space-inset-two;
    }

    @include media-breakpoint-up(lg) {
        padding-right: $spot-space-inset-four;
    }
}

.permissions {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    min-width: 300px;

    @include media-breakpoint-up(md) {
        padding-left: $spot-space-inset-two;
    }

    @include media-breakpoint-up(lg) {
        padding-left: $spot-space-inset-four;
    }
}

.columnTitle {
    font-weight: 700;
    margin-bottom: $spot-space-inset-one-and-a-half;
}

.permissionColumnSectionHeader {
    font-weight: 700;
    line-height: 1.5;
    padding-top: $spot-space-inset-one-and-a-half;
}

.avatarSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    :global {
        .spot-dropdown__content-item--selected {
            border-color: #000 !important;
        }

        .spot-dropdown__caret {
            fill: #00a7b5;
        }
    }
}

.profileImage {
    flex: 0 0 auto;
    width: 86px;
    height: 86px;
    display: flex;
    border-radius: 50%;
    border: 3px solid #cbcfd1;

    & > svg {
        color: $spot-color-neutral-91;
        border-radius: 50%;
        padding: 5px 0 0 0;
    }

    & > img {
        width: $avatar-image-size;
        height: $avatar-image-size;
        border-radius: 50%;
    }
}

.displayNameTitle {
    margin-top: 20px;
    margin-bottom: 5px;
}

.avatarTitle {
    margin-top: 20px;
    margin-bottom: 0;
}

.avatarSubTitle {
    padding-top: 0;
    margin-bottom: 5px;
    font-size: $spot-font-size-s;
    color: $spot-text-color-secondary-on-light;
}

.userNameTitle {
    font-size: $spot-font-size-s;
    color: $spot-text-color-secondary-on-light;
    text-transform: capitalize;
    letter-spacing: 1.5px;
    margin-bottom: 0;
    margin-top: 30px;
}

.userName {
    word-break: break-all;
}

.permissionSection {
    display: flex;
    flex-direction: row;
    border: 1px solid $spot-color-neutral-91;
    border-radius: 5px;
    justify-content: space-between;
    min-height: 130px;
}

.permissionText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 20px;
}

.permissionSiteLabel {
    padding-left: 20px;
    margin-bottom: 0;
}

.permissionSubtext {
    padding-left: 20px;
    padding-right: 50px;
    padding-top: 10px;
    font-size: small;
}

.permissionsImage {
    height: 70px;
    align-self: center;
    margin-right: 30px;
}

.verticalLine {
    display: none;

    @include media-breakpoint-up(md) {
        margin: 0;
        display: flex;
        align-self: stretch;
    }
}

.horizontalLine {
    flex-basis: auto;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.editMyProfileFooter {
    display: flex;
    flex-direction: column;
    height: $small-footer-height;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        height: 75px;
        align-items: center;
        justify-content: space-between;
    }
}

.footerLinks {
    order: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: $spot-space-inset-one-and-a-half;

    @include media-breakpoint-up(md) {
        order: 0;
        margin: 0 0 0 50px;
        min-width: 250px;
        justify-content: flex-start;
    }
}

.footerSave {
    max-width: 100px;
    align-self: center;
    margin-top: $spot-space-inset-two;
    margin-bottom: $spot-space-inset-one-and-a-half;

    @include media-breakpoint-up(md) {
        margin: 0 300px 0 0;
    }
}

.footerSpacer {
    order: 2;
}

.loadingOverlay {
    width: 100%;
    background-color: white;
    opacity: 80%;
    z-index: 999;
    position: absolute;

    @include media-breakpoint-up(xl) {
        width: 100%;
    }
}
