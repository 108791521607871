.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
}

.closeIcon {
    fill: #97999b;
    width: 24px;
    height: 38px;
    margin-right: 10px;
}

.downIcon {
    fill: #00a7b5;
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

.avatarItem {
    width: 30%;
    flex: 0 0 30%;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 3px solid #ccc;

    &:hover {
        border: 3px solid #00a7b5;
    }

    img {
        width: 100%;
        border-radius: 50%;
    }
}

.avatarItemSelected {
    border: 3px solid #000;
}

.avatarsList {
    margin: 20px auto;
    width: 90%;
    flex-wrap: wrap;

    display: flex;
    justify-content: space-between;
}

.header {
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
    padding: 8px;

    h2 {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        width: calc(100% - 30px);
    }
}

.singleAvatar {
    width: 56px;
    border: 3px solid #ccc;
    float: left;
    padding: 0;
    border-radius: 50%;
    margin: 2px;

    &:hover {
        border: 3px solid #00a7b5;
    }

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}

.avatarDropdown {
    width: 100%;
}
