@import '../../../variables';
@import '../../../mixins';

.holder {
    height: $enterprise-page-header-height;
    background-color: $spot-background-color-light-secondary;

    width: 100%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;

    @include set-responsive-parameter-up(top, $enterprise-header-height);

    @include responsive-content();
}

.title {
    flex: 1;
    display: inline-flex;
    align-items: center;
    height: 100%;
}

.titleText {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back {
    margin-left: -$spot-size-icon-l;
    margin-right: -$spot-size-icon-l;
    height: 100%;
    position: absolute;
    z-index: 1;

    &:focus {
        box-shadow: none;
    }
}

.backText {
    display: none;

    @include media-breakpoint-up(lg) {
        display: initial;
    }
}
