.pro-tips-container {
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    //.inner {
    //  margin-left: auto;
    //  margin-right: auto;
    //  width: 400px;
    //  /*whatever width you want*/
    //}
}
