.missing-mapping-page {
    .content {
        margin: 10px;

        .header {
            font-size: $spot-font-size-header-level-3;
        }

        .details {
            font-size: $spot-font-size-s;
            color: $spot-text-color-secondary-on-light;
        }

        .siteName {
            font-weight: $spot-font-weight-bold;
        }

        .mappingType {
            font-size: $spot-font-size-xs;
            font-weight: $spot-font-weight-bold;
        }

        .mappingDescription {
            font-size: $spot-font-size-m;
        }
    }
}
