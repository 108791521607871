@import '~@idexx/spot/dist/styles/shared/spot_tokens';

.hidden {
    visibility: hidden;
}

.container {
    display: flex;
    margin: 0;
}

.radio {
    display: flex;
    margin: 0 -8px 0 12px;

    :global(.spot-form__radio-input + .spot-form__radio-inner) {
        box-shadow: none;
    }
}
