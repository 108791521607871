@import '../../../variables';
@import '~@idexx/spot/dist/styles/shared/color';
@import '../../../mixins';

$avatar-icon-size: 112px;
$smallFooterHeight: 130px;

.errorMessage {
    margin-top: 20px;
    order: 0;
}

.container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.editUserPageContent {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.userInformation {
    order: 0;
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    margin-top: 20px;
    min-width: 300px;
}

.siteAccessInfo {
    width: 100%;
    min-height: 100px;
    background-color: #4c4d4e;
    border-color: #3c3d3e;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
}

.infoIconBox {
    width: 50px;
    background-color: #3c3d3e;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.siteAccessInfoText {
    flex: 10;
    margin: auto;
    color: white;
    padding: 8px 16px;
}

.permissions {
    order: 1;
    display: flex;
    flex-basis: 60%;
    flex-direction: column;
    margin-top: 20px;
    min-width: 300px;
    @include media-breakpoint-up(lg) {
        margin-left: 50px;
    }
}

.displayNameHeader {
    order: 0;
    margin-bottom: 30px;
    font-size: 42px;
    font-weight: 100;
    padding-left: 25px;
}

.columnTitle {
    order: 0;
    font-weight: 700;
    margin-bottom: 20px;
}

.permissionColumnSectionHeader {
    font-weight: 700;
    line-height: 1.5;
    padding-top: 20px;
}

.avatarSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    :global {
        .spot-dropdown__content-item--selected.spot-dropdown__content-item--selected {
            border-color: #000;
        }

        .spot-dropdown__caret {
            fill: #00a7b5;
        }
    }
}

.avatarText {
    order: 0;
    padding-top: 10px;
    padding-right: 10px;
    @include media-breakpoint-up(md) {
        order: 1;
    }

    @include media-breakpoint-up(lg) {
        order: 0;
    }
}

.userImage {
    order: 1;
    flex: 0 0 auto;
    width: $avatar-icon-size;
    height: $avatar-icon-size;
    display: flex;
    border-radius: 50%;
    border: 3px solid #cbcfd1;
    margin-right: 30px;

    & > svg {
        color: $spot-color-neutral-91;
        border-radius: 50%;
        padding: 5px 0 0 0;
    }

    @include media-breakpoint-up(md) {
        order: 0;
    }

    @include media-breakpoint-up(lg) {
        order: 1;
    }
}

.avatarTitle {
    margin-top: 20px;
    margin-bottom: 0;
}

.avatarSubTitle {
    padding-top: 0;
    margin-bottom: 5px;
    font-size: $spot-font-size-s;
    color: $spot-text-color-secondary-on-light;
}

.profileImage {
    flex: 0 0 auto;
    width: 86px;
    height: 86px;
    display: flex;
    border-radius: 50%;
    border: 3px solid #cbcfd1;

    & > svg {
        color: $spot-color-neutral-91;
        border-radius: 50%;
        padding: 5px 0 0 0;
    }

    & > img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
}

.displayNameSection {
    order: 2;
}
.displayNameTitle {
    margin-top: 20px;
    margin-bottom: 5px;
}

.displayNameInput {
    padding-right: 30px;
}

.userNameSection {
    order: 3;
}

.userNameTitle {
    font-size: $spot-font-size-s;
    color: $spot-text-color-secondary-on-light;
    text-transform: capitalize;
    letter-spacing: 1.5px;
    margin-bottom: 0;
    margin-top: 30px;
}

.userName {
    padding-right: 30px;
    word-break: break-all;
}

.permissionSection {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-width: 1px;
    border-color: $spot-color-neutral-91;
    border-radius: 5px;
    margin-bottom: 40px;
    justify-content: space-between;
}

.practiceAccessLabel {
    order: 1;
    padding: 50px 20px;
    margin-bottom: 0;
}

.permissionsImage {
    order: 2;
    height: 70px;
    align-self: center;
    margin-right: 30px;
}

.verticalLine {
    display: none;
    @include media-breakpoint-up(md) {
        display: flex;
        flex: 1, 1, auto;
    }
}

.horizontalLine {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.editUserFooter {
    display: flex;
    flex-direction: column;
    height: $smallFooterHeight;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        height: 75px;
        align-items: center;
        justify-content: space-between;
    }
}

.footerLinks {
    order: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
        order: 0;
        margin: 0 0 0 50px;
        min-width: 250px;
        justify-content: flex-start;
    }
}

.footerCancel {
    order: 0;
}

.footerSave {
    order: 0;
    max-width: 100px;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
        order: 1;
        margin: 0 300px 0 0;
    }
}

.footerSpacer {
    order: 2;
}
