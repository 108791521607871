@import '../../../variables';

.input {
    flex: 1;
    padding-top: 0px;
    padding-bottom: 0px;
}

.inputBase {
    padding: 0px 4px 0px 6px !important;
}

.textField {
    &,
    &:global(.spot-search-bar__input) {
        flex-basis: auto;
        display: flex;

        &:global(:read-only) {
            opacity: 1;
        }
    }
}

.notchedOutline {
    display: none;
}
