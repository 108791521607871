@import '../../mixins';
@import '../../spot';

.card {
    @extend .spot-card !optional;
    height: fit-content;
    min-height: 0;
    box-shadow: 0 0 1px 0;
    order: 1;
    max-width: 620px;
    min-width: 350px;
    margin: 17px auto 32px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;

    &:hover {
        box-shadow: 0 0 6px 0 #6a6b6d;
    }

    @include media-breakpoint-up(xl) {
        width: 275px;
        min-width: 275px;
        flex-direction: column;
        margin: 1px 4px 32px;
    }
}

.header {
    @extend .spot-typography__heading--level-2;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 16px 0;
}

.links {
    display: flex;
    align-items: center;
    justify-content: space-between;

    order: 3;
    border-top: 1px solid #e6e8ea;
    padding: 24px;
    width: 100%;

    @include media-breakpoint-up(xl) {
        padding: 14px 24px;
    }
}

.image {
    margin-left: auto;
    margin-right: auto;
    order: 2;
    width: 25%;
    height: auto;
    align-self: center;
    padding: 24px 24px 24px 0;

    @include media-breakpoint-up(xl) {
        width: 90%;
        margin-bottom: 16px;
        padding: 24px;
    }
}
.link {
    line-height: 24px;
    margin-right: 2px;
}

.textAndImage {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-up(xl) {
        flex-direction: column;
    }
}

.text {
    order: 1;
    padding: 24px;
    width: 75%;

    @include media-breakpoint-up(xl) {
        padding: 24px 24px 0 24px;
        width: 100%;
    }
}

.closeIconContainer {
    position: absolute;
    top: 0;
    right: 0;
    padding: $spot-space-s;
    display: inline-flex;
    cursor: pointer;

    &:hover .closeIcon {
        fill: $spot-interactive-color-on-light;
    }

    .closeIcon {
        fill: $spot-color-neutral-74;
        height: $spot-size-icon-s;
        width: $spot-size-icon-s;
    }
}
