@import '../../variables';

.container {
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 1000;
    background-color: $spot-color-teal-35;
    border-radius: 50%;
    padding: 16px;
}

.icon {
    width: 50px;
    height: 50px;
    fill: white;
}
