@import '~@idexx/spot/dist/styles/shared/spot_tokens';
@import '~@idexx/spot/dist/styles/shared/typography';

.item-card-component {
    margin: $spot-space-stack-three-quarter;
    box-shadow: #00000020 0 2px 4px;

    small {
        color: $spot-color-neutral-60;
    }

    .collapsible-panel {
        .toggle-control {
            margin-top: 16px;
        }
    }

    .item-card-preview {
        //padding-top: 1.25rem;
        cursor: pointer;
        user-select: none;
    }

    .item-card-row {
        align-items: center;
    }

    .item-card-row-checkbox {
        margin-bottom: 0;
    }

    .item-card-manage-item {
        margin-top: $spot-space-inset-one;
        margin-bottom: $spot-space-inset-one;
        display: inline-flex;

        &:not(:first-child) {
            margin-left: $spot-space-inset-one-and-a-half;
        }
    }

    .item-card-description {
        color: $spot-text-color-secondary-on-light;
    }

    .item-card-manage-content {
        padding-right: 0;
    }

    .item-card-manage-text {
        @include text-secondary();
    }

    &--secondary {
        color: $spot-color-neutral-48;
        margin: 15px 0;
        font-size: 13.5px;
    }
}
