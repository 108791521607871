@import '../../mixins';

.messageSuccessContainer {
    width: fit-content;
    max-width: 90%;
    margin: auto;
    @include media-breakpoint-up(md) {
        max-width: 400px;
    }
}
.messageErrorContainer {
    margin-top: 20px;
}

.messageContent {
    display: flex;
    flex-direction: row;
    order: 1;
    justify-content: space-between;
    align-items: center;
}
.icon {
    order: 0;
    height: 100%;
}
.messageText {
    order: 1;
    padding-right: 10px;
}

.messageHeader {
    font-weight: bold !important;
}

.closeButton {
    order: 2;
    background-color: transparent;
    color: white;
    height: 15px;
    width: 15px;
}
.systemMessageToast {
    padding: 0;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
}

.systemMessageBody {
    margin: 0;
    background-color: transparent;
    box-shadow: none;
}
