@import '~@idexx/spot/dist/styles/shared/spot_tokens';

.dropdown-search-bar {
    @include spot-container-light-parent-selectors {
        border-top: 1px solid $spot-border-control-color-on-light;
        border-bottom: 1px solid $spot-border-control-color-on-light;
        border-left: 1px solid $spot-border-control-color-on-light;
    }
    @include spot-container-dark-parent-selectors {
        border-top: 1px solid $spot-border-control-color-on-dark;
        border-bottom: 1px solid $spot-border-control-color-on-dark;
        border-left: 1px solid $spot-border-control-color-on-dark;
    }

    margin: 0;

    .button {
        margin-top: 6px;

        .text {
            margin-left: 6px;
        }
    }

    .icon {
        float: right;
        width: 12px;
        height: 12px;
        margin-top: 8px;
        margin-right: 5px;
        fill: $spot-border-control-color-on-light;
    }
}

.spot-search-bar:hover {
    .dropdown-search-bar {
        border-color: $spot-color-neutral-60;
    }
}

.spot-search-bar__input:focus ~ .dropdown-search-bar {
    @include spot-focus-styles;
    @include spot-container-light-parent-selectors {
        background-color: $spot-color-white;
        box-shadow: 0 2px 0 0 $spot-interactive-color-on-light, 0 -2px 0 0 $spot-interactive-color-on-light;
        border-left: 1px solid $spot-interactive-color-on-light;

        & .spot-icon {
            fill: $spot-interactive-color-on-light;
        }
    }
    @include spot-container-dark-parent-selectors {
        background-color: $spot-color-neutral-24;
        box-shadow: 0 2px 0 0 $spot-interactive-color-on-dark, 0 -2px 0 0 $spot-interactive-color-on-dark;
        border-left: 1px solid $spot-interactive-color-on-dark;

        & .spot-icon {
            fill: $spot-interactive-color-on-dark;
        }
    }
}

.dropdown-item-active {
    font-weight: $spot-font-weight-bold;
}

.dropdown-item-active-icon {
    @extend .spot-link__icon;
    height: 10px;
    width: 10px;
    margin-left: 5px;
    margin-bottom: 3px;
}

