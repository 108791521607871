// Variables
@import '~@idexx/spot/dist/styles/shared/spot_tokens';
@import '~@idexx/spot/dist/styles/shared/color';
@import '~@idexx/spot/dist/styles/shared/util';
@import '~@idexx/spot/dist/styles/shared/typography';

$font-size: $spot-font-size-m;
$font-family: $spot-font-family-sans-serif;

$row-height: 40px;

$secondary-font-size: $spot-font-size-m;
$secondary-font-weight: $spot-font-weight-bold;

$border-color: $spot-color-white;
$header-background-color: $spot-color-white;
$hover-color: $spot-color-teal-93;

@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-balham/sass/legacy/ag-theme-balham-v22-compat.scss';

.ag-header-container {
    border-bottom: 1px solid $spot-color-neutral-74;
}

.ag-theme-balham .ag-row,
.ag-theme-balham .ag-row:not(.ag-row-first) {
    border-bottom: 1px solid $spot-color-neutral-91;
}

.fill-container {
    height: 100%;
    width: 100%;
}
