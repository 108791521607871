.update-summary-container {
    user-select: none;

    .separator {
        border-radius: 0;
        border: 0 solid rgba(0, 0, 0, 0.125);
        border-bottom-width: 3px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .summaryTitle {
        height: 50px;
        width: 100%;
        padding-top: 14px;
        padding-left: 20px;
        background-color: $spot-background-color-light-secondary;
        margin-bottom: 20px;
        .summaryText {
            font-weight: bold;
        }
    }

    .item-list {
        small {
            color: #979fa5;
        }
    }
}
