@import '../../../../variables';

.datePickerButton {
    margin-left: -$spot-space-inset-half;

    &,
    &:focus,
    &:active {
        outline: none;
    }
}

.datePicker {
    display: flex;
    align-items: center;
    padding: $spot-space-inset-one 0;
    color: $spot-text-color-secondary-on-light;
}
