@import '../../variables';

.noticeContainer {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin: 10px 0 20px 0;
    display: flex;
}

.noticeText {
    padding: 14px;
    background-color: $spot-background-color-light-secondary;
    width: 100%;
    border-radius: 0.25rem;
}

.noticeIcon {
    padding: 14px;
    max-width: 50px;
    max-height: 50px;
    margin: auto;
}
