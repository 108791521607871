@import '../../../variables';
@import '../../../../../../node_modules/@idexx/spot/dist/styles/shared/color';
@import '../../../mixins';
@import '../../../functions';

$header-height: (
    sm: 60px,
    lg: 60px,
    xl: 60px,
);

$row-height: (
    sm: 200px,
    lg: 175px,
    xl: 94px,
);

:export {
    headerSm: strip-unit(map-get($header-height, sm));
    headerLg: strip-unit(map-get($header-height, lg));
    headerXl: strip-unit(map-get($header-height, xl));
    rowSm: strip-unit(map-get($row-height, sm));
    rowLg: strip-unit(map-get($row-height, lg));
    rowXl: strip-unit(map-get($row-height, xl));
    connectionPendingColor: $spot-color-yellow-59;
    connectionErrorColor: $spot-color-red-60;
}

.headerContainer {
    display: flex;
    align-items: center;

    height: map-get($header-height, lg);

    @include media-breakpoint-up(xl) {
        height: map-get($header-height, xl);
    }
}

.row {
    background-color: transparent !important;

    &.rowError {
        &,
        &:hover {
            background-color: $spot-color-sales-red-96 !important;
        }
    }
}

.headerCell {
    @include contentLabel();
    text-transform: uppercase;
}

.cellContainer {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-height: 100%;
}

.cell {
    white-space: normal;
    color: $spot-text-color-secondary-on-light;
    max-width: 100%;
}

.cellMobile {
    @extend .cell;

    display: flex;
    flex-direction: column;
    width: 100%;
}

.cellName {
    @extend .cell;

    display: flex;
    flex-direction: column;
}

.mobilePracticeName {
    display: flex;
    flex-direction: column;
    margin-bottom: $spot-space-inset-three-quarter;
}

.mobileInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: $spot-space-inset-one;

    & > *:not(:last-child) {
        margin-bottom: $spot-space-inset-half;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;

        & > *:not(:last-child) {
            margin-right: $spot-space-inset-one-and-a-half;
            margin-bottom: 0;
        }
    }
}

.toggle {
    display: flex;

    label {
        margin: 0;
    }
}

.name {
    @include ellipsis();
}

.practiceInfo {
    @include ellipsis();

    color: $spot-text-color-secondary-on-light;
}

.pims {
    @include ellipsis();

    color: $spot-text-color-secondary-on-light;
}

.labels {
    @include ellipsis();

    color: $spot-text-color-secondary-on-light;
}

.refreshButton {
    background: $spot-color-green-45;
    border-radius: 15px;
    box-shadow: none;
    margin-left: 20px;

    &:hover {
        box-shadow: none;
    }

    &:disabled,
    &[disabled] {
        background: white !important;
        color: $spot-text-color-secondary-on-light;
        border: 1px solid $spot-text-color-secondary-on-light;

        & > svg {
            fill: $spot-text-color-secondary-on-light;
        }
    }
}
