@import './variables';
@import '~bootstrap/scss/mixins/breakpoints';

@mixin responsive-content() {
    padding-left: map-get($base-content-padding, xs);
    padding-right: map-get($base-content-padding, xs);

    @include media-breakpoint-up(sm) {
        padding-left: map-get($base-content-padding, sm);
        padding-right: map-get($base-content-padding, sm);
    }

    @include media-breakpoint-up(md) {
        padding-left: map-get($base-content-padding, md);
        padding-right: map-get($base-content-padding, md);
    }

    @include media-breakpoint-up(lg) {
        padding-left: map-get($base-content-padding, lg);
        padding-right: map-get($base-content-padding, lg);
    }

    @include media-breakpoint-up(xl) {
        padding-left: map-get($base-content-padding, xl);
        padding-right: map-get($base-content-padding, xl);
    }
}

@mixin set-responsive-parameter-up($param-name, $breakpoints, $prefix: '') {
    @each $name, $value in $breakpoints {
        @if $name == xs {
            #{$param-name}: #{$prefix}$value;
        } @else {
            @include media-breakpoint-up(xl) {
                #{$param-name}: #{$prefix}$value;
            }
        }
    }
}

@mixin ellipsis($display: block) {
    display: $display;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin contentLabel() {
    font-size: $spot-font-size-s;
    color: $spot-text-color-secondary-on-light;
    text-transform: capitalize;
    letter-spacing: 1.5px;
}
